import { css } from '@emotion/react'
import { brandColor, brandHoverColor } from '@/styles/constant'
import { Button, TurtleTheme, useTurtleTheme } from '@dmm-com/turtle-components'
import { ComponentProps } from 'react'

type AdditionalColor = {
  mainColor?: string
  hoverColor?: string
  textColor?: string
}

type Props = AdditionalColor & ComponentProps<typeof Button>

// 基本的にはbrandColorを利用するが、特定の場所で色を変えたい場合はpropsで指定
export const BrandButton = ({
  mainColor = brandColor,
  hoverColor = brandHoverColor,
  textColor = '#fff',
  ...other
}: Props) => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme, { mainColor, hoverColor, textColor })

  return <Button css={styles.button} {...other} />
}

const createStyles = (theme: TurtleTheme, additionalColor: AdditionalColor) => {
  const { mainColor, hoverColor, textColor } = additionalColor
  return {
    button: css`
      background-color: ${mainColor};
      color: ${textColor};
      position: relative;

      &.outline {
        border-color: ${mainColor};
        color: ${mainColor};
        background-color: ${theme.turtle.palette.surfaceColor.primarySurface};
        outline: none;

        &:hover {
          border-color: ${mainColor};
          background-color: rgba(44, 44, 44, 0.185) !important;
        }

        &:focus-visible {
          border: 1px solid #005fcc;
        }
      }

      &::before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: solid 2px transparent;
        border-radius: 9999px;
      }

      &:hover {
        background-color: ${hoverColor} !important;
      }
      &:focus-visible {
        &::before {
          border: 1px solid #005fcc;
        }
      }

      // type Link時のスタイルを上書き
      & > a {
        position: relative;
        z-index: 1;
        background-color: ${mainColor};

        &:hover {
          background-color: ${hoverColor} !important;
        }
      }

      &.outline > a {
        border-color: ${mainColor};
        color: ${mainColor};
        background-color: ${theme.turtle.palette.surfaceColor.primarySurface};

        &:hover {
          border-color: ${mainColor};
          background-color: rgba(44, 44, 44, 0.185) !important;
        }
      }
    `,
  }
}
